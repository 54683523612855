<template>
    <div class="container" style="background-color: #fff;min-height: calc(100vh - 300px);">
        <h1>{{ $store.state.language.agent.login_title }}</h1>
        <div class="row">
            <div class="col-12 offset-md-4 col-md-4 form-group">
                <label for="acc">{{ $store.state.language.agent.login_account }}</label>
                <input type="text" id="acc" class="form-control" v-model="acc" />
            </div>
            <div class="col-12 offset-md-4 col-md-4 form-group">
                <label for="pw">{{ $store.state.language.agent.login_password }}</label>
                <input type="password" id="pw" class="form-control" v-model="pw" v-on:keyup.enter="send_form()" />
            </div>
            <div class="col-12 form-group text-center my-2">
                <button class="btn btn-primary" @click="send_form()">
                    <i class="fas fa-sign-in-alt"></i>
                    {{ $store.state.language.agent.login_btn }}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, {
    siteKey: "6LfHnYAeAAAAAG763J4o-g4G3BntOmYdW4--CzYm",
    loaderOptions: {
        useRecaptchaNet: true
    }
});
export default {
    data() {
        return {
            acc: "",
            pw: "",
            token: "",
            check_form: false
        };
    },
    mounted() {
        this.recaptcha();
        setInterval(this.recaptcha, 110000);
    },
    methods: {
        async recaptcha() {
            await this.$recaptchaLoaded();
            // Execute reCAPTCHA with action "login".
            this.token = await this.$recaptcha("login");
        },
        send_form() {
            let vue = this;
            vue.$store.dispatch("login", {
                data: { token: vue.token, acc: vue.acc, pw: vue.pw },
                callback(res) {
                    if (res.data.status == "ok") {
                        vue.$swal
                            .fire({
                                icon: "success",
                                title:
                                    vue.$store.state.language.agent
                                        .login_success,
                                timer: 1500
                            })
                            .then(() => {
                                vue.$router.push("/agent/list/");
                            });
                    } else {
                        vue.$swal.fire({
                            icon: "error",
                            title: vue.$store.state.language.agent.login_fail,
                            didClose() {
                                location.reload();
                            }
                        });
                    }
                }
            });
        }
    }
};
</script>
